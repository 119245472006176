import React from "react";
import "./Gallery.css";

const Gallery = () => {
  const images = [
    "/images/DØDSEGO_FRONTEND3.jpg",
    "/images/DØDSEGO_FRONTEND4.jpg",
    "/images/DØDSEGO_FRONTEND5.jpg",
  ];

  return (
    <section id="gallery">
      <br></br>
      <br></br>
      <br></br>

      {images.map((image, index) => (
            
        <div className="img">
            <img key={index} src={image} alt={`Gallery Image ${index + 1}`} width="100%" height="auto"/>
            {index === 0 && (
              <div className="overlay-text">

              
            </div>
            )}
            {index === 1 && (
              <div className="overlay-text">
                <p className="gallery-text">
                <div className="members">



</div>
                </p>
              </div>
            )}
        </div>
      
      ))}
    </section>
  );
};

export default Gallery;