import React, { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

const Login = () => {
  const { login } = useContext(AuthContext);
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [token, setToken] = useState(null);  
  const [protectedMessage, setProtectedMessage] = useState("");
  const handleLogin = async () => {
      try {
        const response = await axios.post("http://localhost:5005/api/login", formData);
        setToken(response.data.token);
        window.location.href = "/dashboard"; // Redirect after login
      } catch (error) {
        alert(error.response?.data?.message || "Error logging in");
      }
    };
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const accessProtectedRoute = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/protected", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProtectedMessage(response.data.message);
      } catch (error) {
        alert(error.response?.data?.message || "Error accessing protected route");
      }
    };


  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Login</h1>
      <div style={{ padding: "20px" }}>
      <h1>User Authentication</h1>

      <input
        type="text"
        name="username"
        placeholder="Username"
        value={formData.username}
        onChange={handleChange}
      />
      <br />
      <input
        type="password"
        name="password"
        placeholder="Password"
        value={formData.password}
        onChange={handleChange}
      />
      <br />
      <button onClick={handleLogin}>Login</button>
      <button onClick={accessProtectedRoute} disabled={!token}>
        Access Protected Route
      </button>

      {protectedMessage && <p>{protectedMessage}</p>}
    </div>
    </div>
  );
};

export default Login;