import React, { useState } from 'react';
import './Shows.css';

// Sample data simulating the MongoDB database
const eventsData = [
  {
    "id": 1,
    "type": "gig",
    "date": "2022-05-13T00:00:00Z",
    "city": "Oslo",
    "venue": "Hausmania",
    "title": "Meltfest",
    "description": "Rockefestival",
    "metadata": { "previous_name": "Rainbowhead" }
  },
  {
    "id": 2,
    "type": "gig",
    "date": "2022-10-07T00:00:00Z",
    "city": "Oslo",
    "venue": "Hausmania",
    "title": "Meltfest",
    "description": "Rockefestival",
    "metadata": { "previous_name": "Rainbowhead" }
  },
  {
    "id": 3,
    "type": "gig",
    "date": "2022-11-26T00:00:00Z",
    "city": "Oslo",
    "venue": "Uhørt",
    "title": "Support for Hekkan Tekla",
    "description": "",
    "metadata": { "previous_name": "Rainbowhead" }
  },
  {
    "id": 4,
    "type": "gig",
    "date": "2023-05-13T00:00:00Z",
    "city": "Oslo",
    "venue": "Krøsset",
    "title": "Support for Pinkertons",
    "description": "",
    "metadata": { "previous_name": "Rainbowhead" }
  },
  {
    "id": 5,
    "type": "gig",
    "date": "2023-05-26T00:00:00Z",
    "city": "Oslo",
    "venue": "Gamla",
    "title": "Releasegig",
    "description": "Med gjesteopptreden av Sofie Almåsvold",
    "metadata": { "previous_name": "Rainbowhead" }
  },
  {
    "id": 6,
    "type": "gig",
    "date": "2023-08-18T00:00:00Z",
    "city": "Oslo",
    "venue": "SALT",
    "title": "Splitgig with Future Nostalgia",
    "description": "",
    "metadata": { "previous_name": "Rainbowhead" }
  },
  {
    "id": 7,
    "type": "gig",
    "date": "2024-01-23T00:00:00Z",
    "city": "Oslo",
    "venue": "Café Mir",
    "title": "Split Dødsego x Oliana",
    "description": "",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 8,
    "type": "gig",
    "date": "2024-02-24T00:00:00Z",
    "city": "Oslo",
    "venue": "Uhørt",
    "title": "Split Dødsego x Main Street Revival",
    "description": "",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 9,
    "type": "gig",
    "date": "2024-04-04T00:00:00Z",
    "city": "Oslo",
    "venue": "John Dee",
    "title": "Support for Drongo",
    "description": "",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 10,
    "type": "gig",
    "date": "2024-06-08T00:00:00Z",
    "city": "Oslo",
    "venue": "Vega Scene",
    "title": "VFX-awards",
    "description": "",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 11,
    "type": "gig",
    "date": "2024-07-13T00:00:00Z",
    "city": "Åndalsnes",
    "venue": "Trollkaukfestivalen",
    "title": "",
    "description": "",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 12,
    "type": "gig",
    "date": "2024-08-06T00:00:00Z",
    "city": "Oslo",
    "venue": "Postkontoret",
    "title": "Klubbdagen, Øyafestivalen",
    "description": "",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 13,
    "type": "gig",
    "date": "2024-09-13T00:00:00Z",
    "city": "Oslo",
    "venue": "Sub Scene",
    "title": "Kulturnatt",
    "description": "Kultur Natt",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 14,
    "type": "gig",
    "date": "2024-09-20T23:59:00Z",
    "city": "Trondheim",
    "venue": "Vuelie, Samfundet Trondheim",
    "title": "Samfundet Trondheim",
    "description": "Studentderhuset i Trondheim",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 15,
    "type": "gig",
    "date": "2024-10-03T00:00:00Z",
    "city": "Oslo",
    "venue": "SALT Art & Music",
    "title": "Split Dødsego x Dune Sea",
    "description": "Big screen performance",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 16,
    "type": "gig",
    "date": "2024-10-04T00:00:00Z",
    "city": "Stavanger",
    "venue": "Folken",
    "title": "Folken stavaner, m/lostwangheads",
    "description": "",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 17,
    "type": "gig",
    "date": "2024-11-08T00:00:00Z",
    "city": "Melhus",
    "venue": "Trøndertun folkehøgskole",
    "title": "Trøndertun folkehøgskole",
    "description": "Konsert og mingling med studentene",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 18,
    "type": "gig",
    "date": "2024-11-09T00:00:00Z",
    "city": "Trondheim",
    "venue": "Rosendal Kafe",
    "title": "Rosendal Kafé, m/Dune Sea",
    "description": "2/2 gigs med Dune Sea",
    "metadata": { "current_name": "Dødsego" }
  },
  {
    "id": 19,
    "type": "gig",
    "date": "2025-07-09T00:00:00Z",
    "city": "Åndalsnes",
    "venue": "Trollkaukfestivalen",
    "title": "Trollkauk Festivalen i Åndalsnes",
    "description": "Mellom bakker og berg, rock on!",
    "metadata": { "current_name": "Dødsego" }
  }
];

const Shows = () => {
  const [events, setEvents] = useState(eventsData);
  const [filter, setFilter] = useState({
    type: '',
    city: '',
  });

  const now = new Date();

  const upcomingEvents = events.filter(event => new Date(event.date) >= now);
  const pastEvents = events.filter(event => new Date(event.date) < now).sort((a, b) => new Date(b.date) - new Date(a.date));

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="shows-container">
      {/* Upcoming Events Section */}
      <div className="banner"></div>
      <div className="shows">
        <section>
          <h2>2025</h2>
        <br />

          {upcomingEvents.length > 0 ? (
            <ul>
              {upcomingEvents.map(event => (
                <li className="calObj" key={event.id}>
                  <strong>{event.title}</strong>
                  <p>{formatDate(event.date)} - {event.city} - {event.venue}</p>
                  <p>{event.description}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No upcoming events.</p>
          )}
        </section>

        {/* Past Events Section */}
        <br />
        <br />
        <br />
        <div className="placeholder"></div>
        <section>
          <h2>2024-2023</h2>
          {pastEvents.length > 0 ? (
            <ul className="past-events-list">
              {pastEvents.map(event => (
                <li className="calObj" key={event.id}>
                  <strong>{event.title}</strong>
                  <p>{formatDate(event.date)} - {event.city} - {event.venue}</p>
                  <p>{event.description}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No past events.</p>
          )}
        </section>
      </div>
      <div className="banner"></div>
    </div>
  );
};

export default Shows;