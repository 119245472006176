import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify, faInstagram, faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./Music.css";

const Music = () => {
  return (
    
    <main className="music">
      <section id="music">
        <div class="container">
          <br></br>
          <br></br>
          <br></br>
          <div class="headline-wrapper">
              <h1 className="headtitle">Reborn</h1>
              <div className="backborderhd"></div>
          </div>
          
          <div class="image-wrapper">
            <a href="https://open.spotify.com/artist/2nKkYeW42RSx0jPNKQZ64U?si=e4FrAbw0SGaWav4Jg5SaEA" target="_blank"><img src="/images/REBORN_ALBUMCOVER.jpg" alt="Reborn Artwork"></img></a>
          </div>
          <div class="subtitle-wrapper">
              <p className="subtitle">Latest Release</p>
              <div className="backborder"></div>
          </div>
          <div className="social-icons">
          <div style={{ display: "flex", justifyContent: "center", gap: "1rem", marginTop: "20px" }}>
            <a href="https://open.spotify.com/artist/2nKkYeW42RSx0jPNKQZ64U?si=e4FrAbw0SGaWav4Jg5SaEA" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSpotify} size="2x" color="lightgreen"/>
            </a>
            <a href="https://www.instagram.com/dodsegoband/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" color="purple"/>
            </a>
            <a href="https://facebook.com/dodsego" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" color="blue"/>
            </a>
            <a href="https://www.youtube.com/@Dodsego" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x" color="red"/>
            </a>
            <a href="mailto:dodsego@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} size="2x" color="yellow"/>
            </a>
          </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Music;