import React, { useEffect, useState } from "react";
import socket from "../socket";
import TodoList from "./Todolist";
import Events from "./Events";

const Dashboard = () => {
  const [background, setBackground] = useState("");
  const [newBackground, setNewBackground] = useState("");

  const updateBackground = () => {
    setBackground(newBackground);
  };

  return (
    <div style={{ backgroundImage: `url(${background})`, minHeight: "100vh" }}>
      <h1>Dashboard</h1>
      <div>
        <h2>Change Background</h2>
        <input
          type="text"
          placeholder="Enter image URL"
          value={newBackground}
          onChange={(e) => setNewBackground(e.target.value)}
        />
        <button onClick={updateBackground}>Update Background</button>
      </div>
      <Events />
      <TodoList />
    </div>
  );
};

export default Dashboard;