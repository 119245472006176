import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <header className="header">
      <NavLink to="/" className="logo-link">
        <img src="./images/Fontego_Hvit_nB.png" alt="Logo" className="logo" />
      </NavLink>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
        <ul className="nav-list">
          <li><NavLink to="/music" activeClassName="active">MUSIC</NavLink></li>
          <li><NavLink to="/shows" activeClassName="active">SHOWS</NavLink></li>
          <li><NavLink to="/gallery" activeClassName="active">GALLERY</NavLink></li>
          <li><NavLink to="/bio" activeClassName="active">BIO</NavLink></li>
          <li><NavLink to="/connect" activeClassName="active">CONNECT</NavLink></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;