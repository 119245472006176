import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";
import "./App.css"; // Import the CSS file for transitions

const App = () => (
  <Router>
    <Header />
    <AnimatedRoutes />
  </Router>
);

export default App;