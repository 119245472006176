import React, { useEffect, useState } from "react";
import socket from "../socket";

const TodoList = () => {
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState("");

  useEffect(() => {
    fetch("http://localhost:3001/api/todos")
      .then((res) => res.json())
      .then((data) => setTodos(data));

    socket.on("new-todo", (todo) => {
      setTodos((prevTodos) => [...prevTodos, todo]);
    });

    return () => {
      socket.off("new-todo");
    };
  }, []);

  const addTodo = () => {
    fetch("http://localhost:3001/api/todos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ text: newTodo, date: new Date() }),
    }).then(() => setNewTodo(""));
  };

  return (
    <div>
      <h2>To-Do List</h2>
      <ul>
        {todos.map((todo, index) => (
          <li key={index}>{todo.text}</li>
        ))}
      </ul>
      <input
        type="text"
        placeholder="New todo"
        value={newTodo}
        onChange={(e) => setNewTodo(e.target.value)}
      />
      <button onClick={addTodo}>Add Todo</button>
    </div>
  );
};

export default TodoList;