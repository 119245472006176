import React from "react";
import "./FrontPage.css";

const FrontPage = () => {
  return (
    <main className="front-page">
      <section id="frontpage">
      </section>
    </main>
  );
};

export default FrontPage;