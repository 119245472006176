import React from "react";
import "./AnimatedRoutes.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./Dashboard";
import Login from "./Login";
import FrontPage from "./components/FrontPage";
import Music from "./components/Music";
import Shows from "./components/Shows";
import Gallery from "./components/Gallery";
import Bio from "./components/Bio";
import Connect from "./components/Connect";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames={"fade"}
        timeout={2000}
      >
        <Routes location={location}>
          <Route exact path="/" element={<FrontPage />} />
          <Route path="/music" element={<Music />} />
          <Route path="/shows" element={<Shows />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/bio" element={<Bio />} />
          <Route path="/connect" element={<Connect />} />
          <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default AnimatedRoutes;