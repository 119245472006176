import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpotify,
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./Connect.css"; // Import the external CSS file

const Connect = () => {
  const [email, setEmail] = useState("");
  const [receipt, setReceipt] = useState("....................");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:5005/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setReceipt("Din epost er registrert!");
      } else {
        setReceipt("Noe gikk galt. Kontakt oss gjerne på dodsego@gmail.com");

      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred.");
    }
  };

  return (
    <div className="landing-page" id="connect">
      <div className="signup">
        <div className="social-icons-bottom">
          <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "1rem", marginTop: "20px" }}>
            <a href="https://open.spotify.com/artist/2nKkYeW42RSx0jPNKQZ64U?si=e4FrAbw0SGaWav4Jg5SaEA" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSpotify} size="2x" />
            </a>
            <a href="https://www.instagram.com/dodsegoband/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="https://facebook.com/dodsego" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://www.youtube.com/@Dodsego" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
            <a href="mailto:dodsego@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </a>
          </div>
        </div>
        {/* Newsletter Signup */}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            className="newsletter-input"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <br></br>
          <br></br>
          <button type="submit" className="newsletter-button">Submit</button>
        </form>
        <p className="receipt">{receipt}</p>
      </div>
    </div>
  );
};

export default Connect;